

let ref = [
    "CV",
    "BP",
    "Unique_id",
    "Date_recept_bdc",
    "Quantite_commande",
    "Reference",
    "Designation",
    "Marque",
    "Reference_specifique_fournisseur",
    "Nom_livraison",
    "Prenom_livraison",
    "Adresse_livraison",
    "Adresse_comp_livraison",
    "Cp_livraison",
    "Ville_livraison",
    "Tel1_livraison",
    "Tel2_livraison",
    "Mail_livraison",
    "Poids"
];

export const isHeaderError = (header: string[]) => {

    if (header.length === ref.length) {
        let error;
        let errorStatus;
        for (let i = 0; i < ref.length; i++) {
            if (ref[i].toLowerCase() !== header[i].toLowerCase()) {
                error = `Colonne ${ref[i]} manquante.`;
                errorStatus = true;
            }
        }
        if (errorStatus === undefined) {
            error = 'OK';
            errorStatus = false;
        }
        return {isError: errorStatus, message: error}
    } else {
        let error = "Le tableau n'a pas la bonne longueur."
        return {isError: true, message: error}
    }
};

const keyRef = [
    '__EMPTY_1',
    '__EMPTY_8',
    '__EMPTY_9',
    '__EMPTY_10',
    '__EMPTY_12',
    '__EMPTY_13',
    '__EMPTY_14',
    '__EMPTY_16',
    '__EMPTY_17',
]

const refError = {
    __EMPTY_1: "Unique_id",
    __EMPTY_8: "Nom",
    __EMPTY_9: "Prénom",
    __EMPTY_10: "Adresse",
    __EMPTY_12: "Code-Postal",
    __EMPTY_13: "Ville",
    __EMPTY_14: "Numéro de téléphone",
    __EMPTY_16: "Email",
    __EMPTY_17: "Poids"
}

const checkKey = (data: object) => {
    let error;
    let errorMessage;
    let keyData = Object.keys(data)
    for (let i = 0; i < keyRef.length; i++) {
        if (!keyData.includes(keyRef[i])){
            error = true;
            errorMessage = keyRef[i];
        }
    }
    if (error) {
        // @ts-ignore
        return {error: error, errorMessage: refError[errorMessage]}
    } else {
        return {error: false, errorMessage: 'OK'}
    }
}

const checkContent = (data: object) => {
    let error;
    let errorMessage;
    for (let i = 0; i < keyRef.length; i++) {
        // @ts-ignore
        if (!data[keyRef[i]]) {
            error = true;
            errorMessage = keyRef[i];
        }
    }
    if (error) {
        // @ts-ignore
        return {error: error, errorMessage: refError[errorMessage]}
    } else {
        return {error: false, errorMessage: 'OK'}
    }
}

export const isContentError = (data: object[]) => {
    let error;
    let errorMessage;

    for (let i = 0; i < data.length; i++) {
        let object = data[i];
        if (checkKey(object).error) {
            error = true;
            errorMessage = `Contenu manquant ligne ${i +1}, colonne ${checkKey(object).errorMessage} vide. Ce contenu est requis.`
        } else {
            if (checkContent(object).error) {
                error = true;
                errorMessage = `Contenu manquant ligne ${i +1}, colonne ${checkContent(object).errorMessage} vide. Ce contenu est requis.`
            }
        }
    }
    if (error) {
        return {error: error, errorMessage: errorMessage}
    } else {
        return {error: false, errorMessage: 'OK'}
    }
};

export const deleteDuplicate = (data: object[]) => {
    let ref: any[] = []
    let newData: any[] = []
    for (let i = 0; i < data.length; i++) {
        // @ts-ignore
        if (!ref.includes(data[i].__EMPTY_1)) {
            // @ts-ignore
            ref.push(data[i].__EMPTY_1)
            newData.push(data[i])
        }
    }
    return newData
}