import React from 'react';
import './Header.scss';
import { Row, Col } from 'antd';
import { Typography } from 'antd';

const { Title } = Typography;

export const Header: React.FC = () => (
    <Row className="header-home">
        <Col span={12} className="header-logo">
                <img className="logo-altyor" src={require('../../assets/Logo Tiloli_White baseline.png')} alt="Logo-Tiloli"/>
        </Col>
        <Col span={12} className="header-title">
            <Title className="title-container">Meyclub/Tiloli-Chrono<em>post</em></Title>
        </Col>
    </Row>
);
