
export const SheetXLS = {
    "!cols": [
        {width: 25.6640625, wpx: 154, wch: 24.83, MDW: 6},
        {width: 25.6640625, wpx: 154, wch: 24.83, MDW: 6},
        {width: 25.6640625, wpx: 154, wch: 24.83, MDW: 6},
        {width: 32.6640625, wpx: 196, wch: 31.83, MDW: 6},
        {width: 35.21875, wpx: 211, wch: 34.33, MDW: 6},
        {width: 23.77734375, wpx: 143, wch: 23, MDW: 6},
        {width: 23.33203125, wpx: 140, wch: 22.5, MDW: 6},
        {width: 20.21875, wpx: 121, wch: 19.33, MDW: 6},
        {width: 58.33203125, wpx: 350, wch: 57.5, MDW: 6},
        {width: 29.6640625, wpx: 178, wch: 28.83, MDW: 6},
        {width: 25.6640625, wpx: 154, wch: 24.83, MDW: 6},
        {width: 42.109375, wpx: 253, wch: 41.33, MDW: 6},
        {width: 44.88671875, wpx: 269, wch: 44, MDW: 6},
        {width: 25.6640625, wpx: 154, wch: 24.83, MDW: 6},
        {width: 39.88671875, wpx: 239, wch: 39, MDW: 6},
        {width: 25.6640625, wpx: 154, wch: 24.83, MDW: 6},
        {width: 29, wpx: 174, wch: 28.17, MDW: 6},
        {width: 24.6640625, wpx: 148, wch: 23.83, MDW: 6},
        {width: 23.33203125, wpx: 140, wch: 22.5, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
        {width: 9.109375, wpx: 55, wch: 8.33, MDW: 6},
    ],
    "!margins": {
        bottom: 0.75,
        footer: 0.3,
        header: 0.3,
        left: 0.25,
        right: 0.25,
        top: 0.75,
    },
    "!ref": "A1:S14",
    A1: {v: "Commande fournisseur", t: "s", z: "General", w: "Commande fournisseur"},
    A2: {v: "", t: "s", z: "General", w: ""},
    A3: {v: "TILOLI", t: "s", z: "General", w: "TILOLI"},
    A4: {v: "TILOLI SAS", t: "s", z: "General", w: "TILOLI SAS"},
    A5: {v: "", t: "s", z: "General", w: ""},
    A6: {v: "Meyclub", t: "s", z: "General", w: "Meyclub"},
    A7: {v: "178 Boulevard Gabriel Péri", t: "s", z: "General", w: "178 Boulevard Gabriel Péri"},
    A8: {v: "92240 Malakoff", t: "s", z: "General", w: "92240 Malakoff"},
    A9: {v: "", t: "s", z: "General", w: ""},
    A10: {v: "Fait le : 18/09/2020", t: "s", z: "General", w: "Fait le : 18/09/2020"},
    A11: {v: "", t: "s", z: "General", w: ""},
    A12: {v: "", t: "s", z: "General", w: ""},
    A13: {v: "CV", t: "s", z: "General", w: "CV"},
    A14: {v: "Facultatif", t: "s", z: "General", w: "Facultatif"},
    B13: {v: "BP", t: "s", z: "General", w: "BP"},
    B14: {v: "Facultatif", t: "s", z: "General", w: "Facultatif"},
    C13: {v: "Unique_id", t: "s", z: "General", w: "Unique_id"},
    C14: {v: "Obligatoire", t: "s", z: "General", w: "Obligatoire"},
    D13: {v: "Date_recept_bdc", t: "s", z: "General", w: "Date_recept_bdc"},
    D14: {v: "Facultatif", t: "s", z: "General", w: "Facultatif"},
    E13: {v: "Quantite_commande", t: "s", z: "General", w: "Quantite_commande"},
    E14: {v: "Facultatif", t: "s", z: "General", w: "Facultatif"},
    F13: {v: "Reference", t: "s", z: "General", w: "Reference"},
    F14: {v: "Facultatif", t: "s", z: "General", w: "Facultatif"},
    G13: {v: "Designation", t: "s", z: "General", w: "Designation"},
    G14: {v: "Facultatif", t: "s", z: "General", w: "Facultatif"},
    H13: {v: "Marque", t: "s", z: "General", w: "Marque"},
    H14: {v: "Facultatif", t: "s", z: "General", w: "Facultatif"},
    I13: {v: "Reference_specifique_fournisseur", t: "s", z: "General", w: "Reference_specifique_fournisseur"},
    I14: {v: "Facultatif", t: "s", z: "General", w: "Facultatif"},
    J13: {v: "Nom_livraison", t: "s", z: "General", w: "Nom_livraison"},
    J14: {v: "Obligatoire", t: "s", z: "General", w: "Obligatoire"},
    K13: {v: "Prenom_livraison", t: "s", z: "General", w: "Prenom_livraison"},
    K14: {v: "Obligatoire", t: "s", z: "General", w: "Obligatoire"},
    L13: {v: "Adresse_livraison", t: "s", z: "General", w: "Adresse_livraison"},
    L14: {v: "Obligatoire", t: "s", z: "General", w: "Obligatoire"},
    M13: {v: "Adresse_comp_livraison", t: "s", z: "General", w: "Adresse_comp_livraison"},
    M14: {v: "Facultatif", t: "s", z: "General", w: "Facultatif"},
    N13: {v: "Cp_livraison", t: "s", z: "General", w: "Cp_livraison"},
    N14: {v: "Obligatoire", t: "s", z: "General", w: "Obligatoire"},
    O13: {v: "Ville_livraison", t: "s", z: "General", w: "Ville_livraison"},
    O14: {v: "Obligatoire", t: "s", z: "General", w: "Obligatoire"},
    P13: {v: "Tel1_livraison", t: "s", z: "General", w: "Tel1_livraison"},
    P14: {v: "Obligatoire", t: "s", z: "General", w: "Obligatoire"},
    Q13: {v: "Tel2_livraison", t: "s", z: "General", w: "Tel2_livraison"},
    Q14: {v: "Facultatif", t: "s", z: "General", w: "Facultatif"},
    R13: {v: "Mail_livraison", t: "s", z: "General", w: "Mail_livraison"},
    R14: {v: "Obligatoire", t: "s", z: "General", w: "Obligatoire"},
    S13: {v: "Poids", t: "s", z: "General", w: "Poids"},
    S14: {v: "Obligatoire", t: "s", z: "General", w: "Obligatoire"},
};