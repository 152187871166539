import React from 'react';
import {Header} from "../header/Header";
import {Row} from 'antd';
import { Converter } from "../converter/Converter";
import "./Home.scss";

export const Home: React.FC = () => {
    return (
        <div className="global-ui">
            <div className="background"></div>
            <Row className="dashboard">
                <div className="header-container">
                    <Header />
                </div>
                <div className="ui-converter">
                    <Converter />
                </div>
            </Row>
        </div>
    );
};